import { makeObservable, observable, action, autorun, computed } from 'mobx';
import _pick from 'lodash/pick';

import wordpressData from 'data';
import { SearchState, urlToSearchState } from 'search/document-search';
import SearchStore from 'search/stores/search-store';

export const DEFAULT_HITS_PER_PAGE = 12;

interface SavedDocumentSearchStore {
  showFilters: boolean;
  searchState: SearchState;
  documentTypeFilterOpened: boolean;
}

export default class DocumentSearchStore implements SearchStore {
  showFilters: boolean = false;
  nbHits: number = 0;
  hasResults: boolean = false;
  /**
   * one based, will be one both when no results or when on first page
   */
  page: number = 1;
  nbPages: number = 0;

  searchState: SearchState = {};

  documentTypeFilterOpened: boolean = true;

  constructor() {
    this.load();
    makeObservable(this, {
      showFilters: observable,
      searchState: observable,
      documentTypeFilterOpened: observable,
      nbHits: observable,
      hasResults: observable,
      page: observable,
      nbPages: observable,
      load: action,
      setShowFilters: action,
      toggleShowFilters: action,
      setSearchState: action,
      setDocumentTypeFilterOpened: action,
      setNbHits: action,
      setPage: action,
      setNbPages: action,
      hitsPerPage: computed,
      sortBy: computed,
    });
    this.save = this.save.bind(this);
    autorun(this.save);
  }

  private save() {
    // console.log('DocumentSearchStore::save', this);
    // save certain properties every time they change
    const store: SavedDocumentSearchStore = {
      showFilters: this.showFilters,
      searchState: {
        hitsPerPage: this.searchState.hitsPerPage,
        sortBy: this.searchState.sortBy,
      },
      documentTypeFilterOpened: this.documentTypeFilterOpened,
    };
    window.localStorage.setItem('documentSearchStore', JSON.stringify(store));
  }

  load() {
    Object.assign(
      this,
      _pick(JSON.parse(window.localStorage.getItem('documentSearchStore') || '{}'), [
        'showFilters',
        'searchState',
        'documentTypeFilterOpened',
      ])
    );
    this.searchState = Object.assign(this.searchState, urlToSearchState(window.location));

    if (window.innerWidth >= 992) {
      // desktop
    } else {
      // mobile
      this.setShowFilters(false);
    }

    // trigger body class toggle
    this.setShowFilters(this.showFilters);
  }

  setShowFilters(showFilters: boolean) {
    this.showFilters = showFilters;
    jQuery('body').toggleClass('is-active--listing-filters', this.showFilters);
  }

  toggleShowFilters() {
    this.setShowFilters(!this.showFilters);
  }

  setSearchState(searchState: SearchState) {
    this.searchState = searchState;
  }

  setDocumentTypeFilterOpened(documentTypeFilterOpened: boolean) {
    this.documentTypeFilterOpened = documentTypeFilterOpened;
  }

  get hitsPerPage() {
    return this.searchState.hitsPerPage || DEFAULT_HITS_PER_PAGE;
  }

  get sortBy() {
    return this.searchState.sortBy || wordpressData.algolia.documentsIndex;
  }

  setNbHits(nbHits: number) {
    this.nbHits = nbHits;
    this.hasResults = nbHits !== 0;
  }

  setPage(page: number) {
    this.page = page;
  }

  setNbPages(nbPages: number) {
    this.nbPages = nbPages;
  }
}
