import { useAbility } from 'auth';
import { MainHero } from 'my-account/components/MainHero';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { ResourcesDashboard } from 'my-account/components/ResourcesDashboard';
import { ResourcesFeatured } from 'my-account/components/ResourcesFeatured';

export default function UniversityPage() {
  const ability = useAbility();
  useDocumentTitle('University');

  const canViewResourcesList = ability.can('list', 'Resource');

  return (
    <>
      <MainHero />

      <section className="c-block c-block--spacing-t-small c-block--spacing-t@md c-block--spacing-b c-block--spacing-b-large@md">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-7@lg">
              <div className="u-text-sm">
                <h5>Symmons University</h5>

                <p>
                  Symmons University is your go-to location for Symmons product videos, training videos, catalogs,
                  industry-specific information, and much more.
                </p>
              </div>
              {canViewResourcesList && <ResourcesDashboard showTitle={false} />}
            </div>

            <div className="o-col-5@lg">
              <div className="c-sidebar c-sidebar--has-divider u-pl-gutter@lg">
                <ResourcesFeatured />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
