import * as React from 'react';

import { subject } from '@casl/ability';
import {
  AdminUserRoleEnum,
  AgencyRepUserRoleEnum,
  RegisteredUser,
  ResourceUserRoleEnum,
  SalesRepUserRoleEnum,
  UserRole,
} from '__generated-api__';
import { AuthStatus, useAbility, useAuth } from 'auth';
import { MenuDropdown, MenuDropdownProps } from 'components/Dropdown';

export const NavigationDropdown: React.VFC<Omit<MenuDropdownProps, 'label' | 'options'>> = (props) => {
  const ability = useAbility();
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }
  const options: MenuDropdownProps['options'] = [
    { href: '/', icon: 'grid-fill', label: 'Dashboard' },
    { href: '/profile', icon: 'user-fill', label: 'Profile' },
  ];

  if (ability.can('list', 'Order')) {
    options.push({ href: '/orders', icon: 'id-fill', label: 'Orders' });
  }
  if (ability.can('list', 'Resource') && user?.role !== ResourceUserRoleEnum.ResourceAccess) {
    options.push({ href: '/university', icon: 'university', label: 'University' });
  }

  if (
    ability.can('list', 'Quote') &&
    (user?.role === SalesRepUserRoleEnum.SalesRep ||
      user?.role === AdminUserRoleEnum.Admin ||
      user?.role === AgencyRepUserRoleEnum.AgencyRep)
  ) {
    options.push({
      href: '/orders/pending',
      icon: 'package-fill',
      label: 'Pending Orders',
    });
  }

  if (ability.can('list', 'Quote')) {
    options.push({
      href: '/quotes',
      icon: 'package-fill',
      label: user?.direct_buyer ? 'Pending Orders' : 'Project quotes',
    });
  }

  if (ability.can('list', 'User')) {
    options.push({
      href: '/users',
      icon: 'users-fill',
      label: 'Users',
      isActive: (match, location) => {
        return match?.isExact === true && new URLSearchParams(location.search).get('role') !== UserRole.SalesRep;
      },
    });
  }
  if (ability.can('list', 'Agency')) {
    options.push({ href: '/agencies', icon: 'office-fill', label: 'Agencies' });
  }
  if (ability.can('list', 'Company')) {
    options.push({ href: '/companies', icon: 'office-fill', label: 'Companies' });
  }

  if (ability.can('list', 'Location')) {
    options.push({ href: '/locations', icon: 'map-fill', label: 'Locations' });
  }

  if (ability.can('list', subject('User', { role: SalesRepUserRoleEnum.SalesRep }))) {
    options.push({
      href: `/users?role=${SalesRepUserRoleEnum.SalesRep}`,
      icon: 'chat-fill',
      label: 'Representatives',
      isActive: (match, location) => {
        return match?.isExact === true && new URLSearchParams(location.search).get('role') === UserRole.SalesRep;
      },
    });
  }

  if (ability.can('list', 'Project')) {
    let spec_packs_url = '/specification-packages';

    if (auth.status === AuthStatus.LoggedIn && auth.currentUser.role === SalesRepUserRoleEnum.SalesRep) {
      spec_packs_url += '?mine=1';
    }

    options.push({
      href: spec_packs_url,
      icon: 'book-fill',
      label: 'Specification Packages',
    });

    options.push({
      href: '/media-library',
      icon: 'gallery-fill',
      label: 'Media Library',
    });
  }

  if (ability.can('list', 'Introduction')) {
    options.push({
      href: '/introductions',
      icon: 'intro-fill',
      label: 'Introductions',
    });
  }

  return <MenuDropdown {...props} label="Account Menu" options={options} />;
};
