import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import addDays from 'date-fns/addDays';
import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';
import subDays from 'date-fns/subDays';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import {
  AdminUserRoleEnum,
  AgencyRepUserRoleEnum,
  CompanyManagerUserRoleEnum,
  LocationManagerUserRoleEnum,
  OrderStatus,
  RegisteredUser,
  RegularUserRoleEnum,
  ResourceUserRoleEnum,
  SalesRepUserRoleEnum,
  UserRelation,
} from '__generated-api__';
import api from 'api';
import { AuthStatus, useAbility, useAuth, useAuthenticatedUser } from 'auth';
import { Can } from 'auth/components/Can';
import DisplayFieldErrors from 'components/Form/FieldError';
import { FormProvider, useForm } from 'components/Form/Form';
import Icon from 'components/icon';
import { useMutation, useQuery } from 'hooks/query';
import { AdminCard } from 'my-account/components/AdminCard';
import { CountPlaceholder } from 'my-account/components/CountPlaceholder';
import { DashboardHeader } from 'my-account/components/DashboardHeader';
import { PhoneNumberLink } from 'my-account/components/PhoneNumber';
import { ProfileImage } from 'my-account/components/ProfileImage';
import { SelectLocation } from 'my-account/components/SelectLocation';
import { SidebarProfilePreview } from 'my-account/components/SidebarProfilePreview';
import { RequestProjectQuoteForm, RequestProjectQuoteReviewProducts } from 'my-account/forms/RequestProjectQuote';
import { ResourcesDashboard } from 'my-account/components/ResourcesDashboard';
import { DisplayPrice } from 'my-account/utils/currency';
import { getUserName } from 'my-account/utils/user';
import { ResourcesFeatured } from 'my-account/components/ResourcesFeatured';

const RecentOrders: React.VFC = () => {
  const ability = useAbility();
  const currentDate = new Date();
  const tomorrowDate = addDays(currentDate, 1);
  const monthAgoDate = subDays(currentDate, 30);
  const canReadOrders = ability.can('list', 'Order');

  const [recentOpenOrders, recentOpenOrdersQuery] = useQuery(
    api.order.listOrders,
    {
      perPage: 1,
      status: OrderStatus.Open,
      orderDateStart: formatISO(monthAgoDate, { representation: 'date' }),
      orderDateEnd: formatISO(tomorrowDate, { representation: 'date' }),
    },
    { enabled: canReadOrders }
  );

  const [recentDeliveredOrders, recentDeliveredOrdersQuery] = useQuery(
    api.order.listOrders,
    {
      perPage: 1,
      status: OrderStatus.Closed,
      orderDateStart: formatISO(monthAgoDate, { representation: 'date' }),
      orderDateEnd: formatISO(tomorrowDate, { representation: 'date' }),
    },
    { enabled: canReadOrders }
  );

  const [recentCanceledOrders, recentCanceledOrdersQuery] = useQuery(
    api.order.listOrders,
    {
      perPage: 1,
      status: OrderStatus.Canceled,
      orderDateStart: formatISO(monthAgoDate, { representation: 'date' }),
      orderDateEnd: formatISO(tomorrowDate, { representation: 'date' }),
    },
    { enabled: canReadOrders }
  );

  const latestOpenOrder =
    typeof recentOpenOrders !== 'undefined' && recentOpenOrders.data.total > 0 && recentOpenOrdersQuery.isSuccess
      ? recentOpenOrders.data.data[0]
      : undefined;

  if (!canReadOrders) {
    return null;
  }

  return (
    <>
      <h5>Recent Orders</h5>
      {latestOpenOrder && (
        <Link
          to={`/orders/${latestOpenOrder.id}`}
          className="c-admin-card c-admin-card--hor c-admin-card--spacing-sm c-admin-card--info u-mb-spacer-section-small u-mb-spacer-base-large@lg"
        >
          <div className="c-admin-card__icon">
            <Icon name="delivery" />
          </div>
          <div className="c-admin-card__content">
            <p className="c-admin-card__subtitle">Latest open order</p>
            {latestOpenOrder.ship_date && (
              <p className="c-admin-card__title">
                Expected by{' '}
                <strong>
                  {utcToZonedTime(parseISO(latestOpenOrder.ship_date), 'America/New_York').toLocaleDateString('en', {
                    month: 'short',
                    day: '2-digit',
                  })}
                </strong>
              </p>
            )}
            <ul className="c-divider-list">
              <li className="c-divider-list__item">{latestOpenOrder.number}</li>
              <li className="c-divider-list__item">
                <strong>
                  <DisplayPrice value={Number(latestOpenOrder.total)} />
                  &nbsp;USD
                </strong>
              </li>
            </ul>
          </div>
        </Link>
      )}
      <div className="c-list-stats-small">
        <p className="c-list-stats-small__title">Last 30 days:</p>

        <div className="c-list-stats-small__list">
          <div className="c-list-stats-small__item">
            {recentOpenOrders && recentOpenOrdersQuery.isSuccess ? (
              <span className="c-list-stats-small__number">{recentOpenOrders.data.total}</span>
            ) : (
              <CountPlaceholder size={52} />
            )}
            <p className="c-list-stats-small__label">
              Open <br />
              orders
            </p>
          </div>

          <div className="c-list-stats-small__item">
            {recentDeliveredOrders && recentDeliveredOrdersQuery.isSuccess ? (
              <span className="c-list-stats-small__number">{recentDeliveredOrders.data.total}</span>
            ) : (
              <CountPlaceholder size={52} />
            )}
            <p className="c-list-stats-small__label">
              Delivered <br />
              orders
            </p>
          </div>

          <div className="c-list-stats-small__item">
            {recentCanceledOrders && recentCanceledOrdersQuery.isSuccess ? (
              <span className="c-list-stats-small__number">{recentCanceledOrders.data.total}</span>
            ) : (
              <CountPlaceholder size={52} />
            )}
            <p className="c-list-stats-small__label">
              Cancelled <br />
              orders
            </p>
          </div>
        </div>
      </div>
      <Link className="c-link-cta" to="/orders">
        <span>View all orders</span>
        <Icon name="arrow" className="o-svg-icon o-svg-right" />
      </Link>
    </>
  );
};

const ProjectQuoteRequest: React.VFC = () => {
  const ability = useAbility();
  const auth = useAuth();
  let user: RegisteredUser | undefined;
  if (auth.status === AuthStatus.LoggedIn) {
    user = auth.currentUser;
  }
  if (!ability.can('create', 'Quote')) {
    return null;
  }

  return (
    <>
      <h5>{user?.direct_buyer ? 'Pending Orders' : 'Project Quote Requests'}</h5>
      <div className="u-text-sm u-mb-spacer-base-large">
        <p>
          {user?.direct_buyer ? (
            <>
              To create a new order, complete the form below. Be as specific as possible in regard to models, sizes,
              finishes, and other details.
            </>
          ) : (
            <>
              To request a quote for your project, complete the form below. Be as specific as possible in regard to
              models, sizes, finishes, and other details.
            </>
          )}
        </p>
      </div>
      <div className="c-admin-card c-admin-card--form">
        <div className="c-admin-card__content">
          <RequestProjectQuoteReviewProducts />
          <RequestProjectQuoteForm className="u-mb-spacer-base-small" />
        </div>
      </div>

      <Link to="/quotes" className="c-link-cta">
        <span>{user?.direct_buyer ? 'View pending orders' : 'View all quotes'}</span>
        <Icon name="arrow" className="o-svg-icon o-svg-right" />
      </Link>
    </>
  );
};

const EvolutionCTA: React.VFC = () => {
  const auth = useAuth();

  if (auth.status !== AuthStatus.LoggedIn && auth.status !== AuthStatus.LoggingOut) {
    return null;
  }
  const user = auth.currentUser;
  if (!user.evolution) {
    return null;
  }

  return (
    <>
      <h5>Evolution Platform</h5>
      <div className="u-text-sm u-mb-spacer-base-large">
        <p>
          See what’s going on inside your water system. Evolution gives time-tracked analyses and customizable 24/7
          alerts for water temperature, flow, usage, and leaks.
        </p>
      </div>
      <a href="https://water.symmons.com" className="c-link-cta" target="_blank" rel="noreferrer">
        <span>Access the Evolution Platform</span>
        <Icon name="arrow" className="o-svg-icon o-svg-right" />
      </a>
    </>
  );
};

const ResourceCTA: React.VFC = () => {
  return (
    <>
      <h5>Symmons University</h5>
      <div className="u-text-sm">
        <p>
          Symmons University is your go-to location for Symmons product videos, training videos, catalogs,
          industry-specific information, and much more.
        </p>
      </div>
      <ResourcesDashboard />
    </>
  );
};

const requestPriceSheetSchema = z.object({
  location: z.object({
    id: z.number().positive(),
    name: z.string().nullable(),
  }),
});

const MyPricingSingleLocation = () => {
  const authUser = useAuthenticatedUser();
  const [requestPriceSheet, requestPriceSheetState] = useMutation(api.pricesheet.createPriceSheet);
  const locationId =
    authUser.role === RegularUserRoleEnum.User || authUser.role === LocationManagerUserRoleEnum.LocationManager
      ? authUser.location?.id ?? undefined
      : undefined;
  const { context, formProps } = useForm({
    schema: requestPriceSheetSchema,
    initialValues: {
      location: {
        id: locationId,
        name: null,
      },
    },
    async onSubmit({ location }) {
      await requestPriceSheet([{ locationId: location.id }]);
    },
  });

  if (!locationId) {
    return null;
  }

  return (
    <FormProvider {...context}>
      <form {...formProps}>
        <div className="o-row o-row--small-gutters u-items-center">
          <div className="o-col-7@lg">
            <h5>Price File</h5>
            <div className="u-text-sm">
              <p className="u-mb-0">Get your most up-to-date Symmons price file.</p>
            </div>
            <DisplayFieldErrors error={context.formState.errors.location} />
          </div>
          <div className="o-col-5@lg u-mt-spacer-base u-mt-0@lg">
            <div className="c-admin-card u-mb-0">
              <button type="submit" className="c-admin-card-button">
                <Icon name="download" className="o-svg-icon" />
                <div className="c-admin-card-button__content">
                  <strong>Download Sheet</strong>
                </div>
              </button>
            </div>
          </div>
          {requestPriceSheetState.isSuccess && (
            <div className="o-col-12 u-mt-spacer-section-small">
              <p className="u-text-secondary u-font-medium u-text-center u-mb-0">
                Your price file has been emailed. Please allow up to 10 minutes for your price file to be delivered.
              </p>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

const MyPricingMultipleLocations = () => {
  const authUser = useAuthenticatedUser();
  const [requestPriceSheet, requestPriceSheetState] = useMutation(api.pricesheet.createPriceSheet);
  const { context, formProps } = useForm({
    schema: requestPriceSheetSchema,
    initialValues: {
      location: undefined,
    },
    async onSubmit({ location }) {
      await requestPriceSheet([{ locationId: location.id }]);
    },
    hasFloatingLabels: true,
  });

  if (authUser.role === RegularUserRoleEnum.User || authUser.role === LocationManagerUserRoleEnum.LocationManager) {
    return null;
  }

  return (
    <FormProvider {...context}>
      <form {...formProps}>
        <h5>Price File</h5>
        <div className="u-text-sm">
          <p className="u-mb-0">Get your most up-to-date Symmons price file.</p>
        </div>
        <div className="o-row o-row--no-gutters u-items-stretch u-mt-spacer-base">
          <div className="o-col-7@lg">
            <div className="c-admin-card c-admin-card--with-right-separator u-mb-0">
              <SelectLocation name="location" label="Location" elStyle="fill" className="u-mb-0" />
            </div>
          </div>
          <div className="o-col-5@lg u-mt-spacer-base u-mt-0@lg">
            <div className="c-admin-card u-mb-0" style={{ height: '100%' }}>
              <button type="submit" className="c-admin-card-button">
                <Icon name="download" className="o-svg-icon" />
                <div className="c-admin-card-button__content">
                  <strong>Download Sheet</strong>
                </div>
              </button>
            </div>
          </div>
          {requestPriceSheetState.isSuccess && (
            <div className="o-col-12 u-mt-spacer-section-small">
              <p className="u-text-secondary u-font-medium u-text-center u-mb-0">
                Your price file has been emailed. Please allow up to 10 minutes for your price file to be delivered.
              </p>
            </div>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default function UserDashboardPage() {
  const user = useAuthenticatedUser();
  const ability = useAbility();

  if (
    user.role === AdminUserRoleEnum.Admin ||
    user.role === SalesRepUserRoleEnum.SalesRep ||
    user.role === AgencyRepUserRoleEnum.AgencyRep
  ) {
    return null;
  }

  let representative: UserRelation | null = null;
  console.log('user', user);
  if (
    user.role !== CompanyManagerUserRoleEnum.CompanyManager &&
    user.role !== ResourceUserRoleEnum.ResourceAccess &&
    user.location &&
    user.location.sales_rep
  ) {
    representative = user.location.sales_rep;
  } else if (user.role !== ResourceUserRoleEnum.ResourceAccess && user.company.sales_rep) {
    representative = user.company.sales_rep;
  }

  const canReadOrders = ability.can('list', 'Order');
  const canRequestProjectQuote = ability.can('create', 'Quote') && user.wholesaler && !user.direct_buyer;
  const canRequestPriceSheet = ability.can('create', 'PriceSheet');
  const canRequestPriceSheetForSingleLocation =
    (canRequestPriceSheet && user.wholesaler && user.role === RegularUserRoleEnum.User) ||
    (user.wholesaler && user.role === LocationManagerUserRoleEnum.LocationManager)
      ? Boolean(user.location)
      : false;
  const canRequestPriceSheetForMultipleLocations =
    user.wholesaler && canRequestPriceSheet && user.role === CompanyManagerUserRoleEnum.CompanyManager;

  return (
    <>
      <DashboardHeader />

      <section className="c-block c-block--spacing-t-small c-block--spacing-t@md c-block--spacing-b c-block--spacing-b-large@md">
        <div className="o-container-fluid">
          <div className="o-row">
            <div className="o-col-7@lg u-mb-spacer-section-small u-mb-0@lg">
              {[
                canReadOrders && <RecentOrders key="recent-orders" />,
                user.evolution && <EvolutionCTA />,
                user.role === ResourceUserRoleEnum.ResourceAccess && <ResourceCTA key="resource-cta" />,
                canRequestPriceSheetForSingleLocation && <MyPricingSingleLocation key="my-pricing" />,
                canRequestPriceSheetForMultipleLocations && <MyPricingMultipleLocations key="my-pricing-locations" />,
                canRequestProjectQuote && <ProjectQuoteRequest key="project-quote-request" />,
              ]
                .filter(Boolean)
                .reduce((prev, current): React.ReactNode[] => {
                  if (!prev.length) {
                    return [current];
                  }

                  return [...prev, <hr className="u-mt-spacer-section-small u-mb-spacer-section-small" />, current];
                }, [] as React.ReactNode[])}
            </div>
            <div className="o-col-5@lg">
              <div className="c-sidebar c-sidebar--has-divider u-pl-gutter@lg">
                <SidebarProfilePreview className="u-mb-spacer-section-small" />
                {user.role === ResourceUserRoleEnum.ResourceAccess && <ResourcesFeatured />}

                <Can I="list" an="User">
                  <AdminCard.Users />
                </Can>
                <Can I="list" a="Location">
                  <AdminCard.Locations />
                </Can>
                {representative && (
                  <div className="c-info c-info--representative">
                    <div>
                      <div className="c-info__header">
                        <div className="c-info__icon u-text-secondary">
                          <Icon name="agent" className="o-svg-icon" />
                        </div>
                        <p className="u-mb-spacer">Your Symmons Representative</p>
                      </div>
                      <div className="c-info__body">
                        <div className="c-info__content">
                          <h5 className="u-mb-spacer">
                            <strong>{getUserName(representative).name}</strong>
                          </h5>
                          {(representative.phone || representative.email) && (
                            <p>
                              {representative.phone && (
                                <>
                                  Call{' '}
                                  <PhoneNumberLink className="c-link-basic">{representative.phone}</PhoneNumberLink>
                                </>
                              )}
                              {representative.phone && representative.email && <> or </>}
                              {representative.email && (
                                <a href={`mailto:${representative.email}`} className="c-link-basic">
                                  send email
                                </a>
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <ProfileImage user={representative} variant="info" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
