import * as React from 'react';
import api from 'api';
import { useQuery } from 'hooks/query';

import useDocumentTitle from 'hooks/useDocumentTitle';
import { AdminCard } from './AdminCard';
import { Link } from 'react-router-dom';
import Icon from 'components/icon';

interface ResourcesDashboardProps {
  showTitle?: boolean;
}

export const ResourcesDashboard: React.VFC<ResourcesDashboardProps> = ({ showTitle }) => {
  useDocumentTitle('University');
  const [resourcesDashboard] = useQuery(api.resource.getResourcesDashboard, undefined);

  return (
    <>
      <div className="o-row">
        <div className="o-col-12@lg">
          {showTitle && <h5 className="u-mb-spacer-base@lg u-mt-spacer-base@lg">Symmons University</h5>}

          {resourcesDashboard?.data.latest && resourcesDashboard.data.latest.length > 0 && (
            <>
              <p className="u-text-xs u-uppercase u-mb-spacer-base-small">Last 30 days:</p>
              <div className="o-row">
                {resourcesDashboard.data.latest.map(
                  (item) =>
                    resourcesDashboard.data.latest && (
                      <div className={'o-col-' + 12 / resourcesDashboard.data.latest.length + '@lg'}>
                        <div className="o-row">
                          <div className="o-col-4@lg" style={{ textAlign: 'right' }}>
                            <h1>
                              <strong>{item.count}</strong>
                            </h1>
                          </div>
                          <div className="o-col-4@lg u-mt-auto u-mb-spacer">
                            <p className="u-text-xs u-uppercase">
                              <strong>{item.term}</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            </>
          )}

          <Link to="/resources" className="c-link-cta u-mt-spacer-base-small u-mb-spacer-base">
            <span>View all University Content</span>
            <Icon name="arrow" className="o-svg-icon o-svg-right" />
          </Link>

          {resourcesDashboard?.data.all_counts &&
            resourcesDashboard.data.all_counts.map(
              (item) =>
                item.count &&
                item.taxonomy_id &&
                item.term && (
                  <AdminCard
                    key={item.taxonomy_id}
                    count={item.count}
                    icon={item.icon ?? ''}
                    label={[item.term, item.term]}
                    listingUrl={'/resources?topic=' + item.taxonomy_id}
                  />
                )
            )}
        </div>
      </div>
    </>
  );
};
