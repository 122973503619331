import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';

import api from 'api';
import { useAbility } from 'auth';
import Icon from 'components/icon';
import Form from 'components/Form/Form';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import { setLocationSearchParams } from 'utils/router';
import { MainHero } from 'my-account/components/MainHero';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import useDocumentTitle from 'hooks/useDocumentTitle';

const scormsFiltersSchema = z.object({
  title: z.string().optional(),
});

const ScormsFilters: React.VFC = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const initialValueParse = scormsFiltersSchema.safeParse(
    Object.assign({
      title: searchParams.get('title') ?? undefined,
    })
  );

  return (
    <>
      <Form
        schema={scormsFiltersSchema}
        initialValues={initialValueParse.success ? initialValueParse.data : {}}
        onSubmit={async (values) => {
          history.replace(
            setLocationSearchParams(location, {
              title: undefined,
            })
          );
        }}
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />
        {/* <SelectField
          name="state"
          label="State/Province"
          allowEmpty
          emptyOptionLabel="Any State/Province"
          elStyle="fill"
          small
        >
          <optgroup label="United States">
            {getObjectKeys(usStates).map((state) => (
              <option key={usStates[state]} value={usStates[state]}>
                {state}
              </option>
            ))}
          </optgroup>
          <optgroup label="Canada">
            {getObjectKeys(caProvinces).map((state) => (
              <option key={caProvinces[state]} value={caProvinces[state]}>
                {state}
              </option>
            ))}
          </optgroup>
        </SelectField>
        <SelectField name="status" label="Status" elStyle="fill" allowEmpty small>
          <option value="1">Active</option>
          <option value="0">Archived</option>
        </SelectField>
        <SelectField name="type" label="Type" elStyle="fill" emptyOptionLabel="Any Type" small>
          <option value="wholesaler">Wholesaler</option>
          <option value="evolution">Evolution</option>
        </SelectField>
        {canListRepresentatives && (
          <SelectUser
            name="rep"
            label="Representative"
            elStyle="fill"
            small
            queryParams={{ role: UserRole.SalesRep }}
            selectProps={{ isClearable: true }}
          />
        )} */}
      </Form>
    </>
  );
};

export default function ScormsPage() {
  useDocumentTitle('Scorm Resources');
  const ability = useAbility();
  const canCreateScorm = ability.can('create', 'Scorm');

  return (
    <>
      <MainHero />

      <DataTableHero
        title="Scorm Resources"
        buttons={
          canCreateScorm ? (
            <>
              <Link to="/resources/add" className="c-button c-button--primary c-button--small u-my-spacer-base-small">
                <Icon name="add" className="o-svg-icon o-svg-right" />
                <span>Add new scorm resource</span>
              </Link>
            </>
          ) : undefined
        }
      />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="scorms"
            availableSortOptions={{
              created_at: 'Latest',
              title: 'Alphabetical',
            }}
            defaultSort="created_at"
            defaultDirection="asc"
            queryFn={api.scorm.listScorms}
            queryFnParams={(filters, searchParams) => {
              return { ...filters };
            }}
            filters={() => <ScormsFilters />}
          >
            {(data) => (
              <>
                {data.map((scorm) => (
                  <Link
                    key={scorm.id}
                    to={`/scorm/tracking?scorm_id=${scorm.id}`}
                    className="c-data-card c-data-card--project"
                  >
                    <div className="c-data-card__column c-data-card__column--first">
                      <p className="c-data-card__title">{scorm.title}</p>
                      <p className="c-data-card__label">Title</p>
                    </div>

                    <div className="c-data-card__column">
                      <p className="c-data-card__title">{scorm.passed_count}</p>
                      <p className="c-data-card__label">Passed</p>
                    </div>

                    <div className="c-data-card__column">
                      <p className="c-data-card__title">{scorm.started_count}</p>
                      <p className="c-data-card__label">Started</p>
                    </div>

                    <div className="c-data-card__column">
                      <button className="c-link-cta-basic c-link-cta--small" type="button">
                        <span>Details</span>
                        <Icon name="arrow" className="o-svg-icon o-svg-right" />
                      </button>
                    </div>
                  </Link>
                ))}
              </>
            )}
          </DataTableListing>
        </div>
      </section>
    </>
  );
}
