import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MultipleQueriesQuery } from '@algolia/client-search';
import { InstantSearch } from 'react-instantsearch-dom';
import { SearchState as AlgoliaSearchState } from 'react-instantsearch-core';
import qs from 'qs';
import wordpressData from 'data';
import DocumentSearchStore from 'search/stores/document-search-store';
import StateResultsReporter from 'search/components/documents-search/state-results-reporter';
import DocumentSearchContent from 'search/components/documents-search/document-search-content';
import VirtualHitsPerPage from './components/virtual-hits-per-page';
import VirtualPagination from './components/virtual-pagination';
import { getAlgoliaClient } from 'utils/algolia';

// console.log('document-search.tsx::wordpressData', wordpressData);

export interface SearchState extends AlgoliaSearchState {
  /**
   * This is not zero based, it shows 1 both when there are no results or when on the first page.
   */
  // page?: number

  /**
   * WARNING: Algolia typings are wrong, properties can be undefined here, e.g. collection property can be undefined!
   */
  refinementList?: {
    // category: string[];
    // collection: string[];
    // finishes: string[];
    // availability: string[];
  };
}

/**
 * This is a proxy search client that we use to route empty searches to WordPress and all other searches to Algolia.
 * This way we prevent initial search results empty query which takes 2/3 of Algolia search requests thus reducing costs.
 * https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/#implementing-a-proxy
 */
const proxySearchClient = {
  search(requests: readonly MultipleQueriesQuery[]) {
    // console.log( 'document-search.tsx::proxySearchClient::mark1' );

    // intercept empty search and pull it from WordPress cache to reduce algolia bill
    // if (
    // 	requests.length === 1
    // 	&& requests[0].params
    // 	&& !requests[0].params.page
    // 	&& !requests[0].params.query
    // 	&& !requests[0].params.tagFilters
    // ) {
    // 	console.log( 'proxySearchClient::mark3' );
    // 	console.log( wordpressData.ajaxUrl );
    // 	return axios
    // 		.get(wordpressData.ajaxUrl, {
    // 			params: {
    // 				action: 'symmons_algolia_product_empty_search',
    // 				facets: requests[0].params.facets,
    // 				hitsPerPage: requests[0].params.hitsPerPage,
    // 			},
    // 		})
    // 		.then((response) => {
    // 			// console.log('proxySearchClient::response=');
    // 			// console.log(response);
    // 			console.log( 'proxySearchClient::mark3.1' );
    // 			return { results: [ response.data ] };
    // 		});
    // }

    // console.log( 'document-search.tsx::proxySearchClient::mark2' );

    return getAlgoliaClient().search(requests);
  },
};

const createURL: (state: any) => string = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl: (props: DocumentSearchProps, searchState: SearchState) => string = (props, searchState) => {
  // // clean up state before we create the URL

  // let searchStateCopy = {...searchState};

  // // use "s" instead of "query" in the URL
  // searchStateCopy.s = searchStateCopy.query;
  // delete searchStateCopy.query;

  // // remove page from the URL if page equals 1 (default)
  // if ( typeof searchStateCopy.page === 'number' && searchStateCopy.page === 1 ) {
  // 	delete searchStateCopy.page;
  // }

  // // remove hitsPerPage from the URL since it's always 21
  // if (
  // 	typeof searchStateCopy.configure === 'object'
  // 	&& searchStateCopy.configure
  // ) {
  // 	delete searchStateCopy.configure.hitsPerPage;
  // }

  // // remove menu type filters if blank
  // if (typeof searchStateCopy.menu === 'object' && searchStateCopy.menu) {
  // 	if (searchStateCopy.menu.category === '') {
  // 		delete searchStateCopy.menu.category;
  // 	}
  // 	if (searchStateCopy.menu.collection === '') {
  // 		delete searchStateCopy.menu.collection;
  // 	}
  // }

  // return searchStateCopy ? `${props.location.pathname}${createURL(searchStateCopy)}` : '';
  return `${props.location.pathname}${createURL(searchState)}`;
};

export const urlToSearchState = (location: any): SearchState => {
  let searchState: SearchState = qs.parse(location.search.slice(1));
  // searchState.query = searchState.s;

  searchState.hitsPerPage = Number(searchState.hitsPerPage || 12);
  searchState.page = Number(searchState.page || 1);

  // console.log('document-search.tsx::urlToSearchState::searchState', searchState);

  return searchState;
};

interface DocumentSearchProps extends RouteComponentProps<any> {
  store: DocumentSearchStore;
  history: any;
  location: any;
}

interface DocumentSearchState {
  searchState: SearchState;
  lastLocation: any;
}

class DocumentSearch extends React.Component<DocumentSearchProps, DocumentSearchState> {
  state: DocumentSearchState = {
    searchState: urlToSearchState(this.props.location),
    lastLocation: this.props.location,
  };

  static getDerivedStateFromProps(props: DocumentSearchProps, state: DocumentSearchState) {
    if (props.location !== state.lastLocation) {
      return {
        searchState: urlToSearchState(props.location),
        lastLocation: props.location,
      };
    }

    return null;
  }

  debouncedSetUrl?: NodeJS.Timeout = undefined;

  onSearchStateChange: (searchState: SearchState) => void = (searchState) => {
    const { store } = this.props;

    if (this.debouncedSetUrl) {
      clearTimeout(this.debouncedSetUrl);
    }

    // console.log('document-search.tsx::onSearchStateChange::searchState', searchState);

    this.debouncedSetUrl = setTimeout(() => {
      this.props.history.push(searchStateToUrl(this.props, searchState), searchState);
    }, 500);

    store.setSearchState(searchState);

    this.setState({ searchState });
  };

  render() {
    // console.log('document-search.tsx::render::state', this.state);

    const { store } = this.props;
    return (
      <>
        <InstantSearch
          indexName={wordpressData.algolia.documentsIndex}
          searchClient={proxySearchClient}
          searchState={this.state.searchState}
          onSearchStateChange={this.onSearchStateChange}
          createURL={createURL}
        >
          <StateResultsReporter store={store} />
          <DocumentSearchContent store={store} defaultHitsPerPage={store.hitsPerPage} />

          {/* Without these virtual widgets, hitsPerPage and page options in this.state.searchState have no effect during the initial render, so the initial query to algolia just sends the engine defaults, then only after the initial render our custom settings in searchState kick in and second query to algolia is performed. By using these virtual widgets we're able to set settings for the initial query, the second query is also still generated but the caching layer does not actually send it to Algolia. The widget props here are not important, we just want the engine not to ignore the keys we set in searchState when the engine is doing the intial query. */}
          <VirtualHitsPerPage defaultRefinement={12} items={[{ value: 12, label: '12' }]} />
          <VirtualPagination defaultRefinement={1} />
        </InstantSearch>
      </>
    );
  }
}

export default DocumentSearch;
