import { Route, Switch } from 'react-router-dom';

import { usePageTracking } from 'hooks/usePageTracking';

import AbilityGuardedRoute from 'my-account/components/AbilityGuardedRoute';
import AuthRoute from 'my-account/components/AuthRoute';
import PrivateRoute from 'my-account/components/PrivateRoute';

import ForgotPasswordPage from 'my-account/pages/auth/forgot-password';
import LoginPage from 'my-account/pages/auth/login';
import RequestAccountPage from 'my-account/pages/auth/request-account';
import SalesforceSSOPage from 'my-account/pages/auth/salesforce-sso';
import VerifyResetPage from 'my-account/pages/auth/verify-reset';
import VerifyWelcomePage from 'my-account/pages/auth/verify-welcome';

import Page404Error from 'my-account/pages/404page';
import DashboardPage from 'my-account/pages/dashboard';
import ProfilePage from 'my-account/pages/profile';

import AgenciesPage from 'my-account/pages/agencies';
import AgencyCreatePage from 'my-account/pages/agencies/add';
import AgencyEditPage from 'my-account/pages/agencies/edit';

import CompaniesPage from 'my-account/pages/companies';
import CompanyCreatePage from 'my-account/pages/companies/add';
import CompanyEditPage from 'my-account/pages/companies/edit';

import LocationsPage from 'my-account/pages/locations';
import LocationEditPage from 'my-account/pages/locations/edit';

import UsersPage from 'my-account/pages/users';
import UserCreatePage from 'my-account/pages/users/add';
import UserEditPage from 'my-account/pages/users/edit';

import OrdersPage from 'my-account/pages/orders';
import PendingOrdersPage from 'my-account/pages/orders/pending';
import OrderViewPage from 'my-account/pages/orders/view';

import QuotesPage from 'my-account/pages/quotes';
import QuoteViewPage from 'my-account/pages/quotes/view';

import SpecificationPackagesPage from 'my-account/pages/specification-packages';
import SpecificationPackageCreatePage from 'my-account/pages/specification-packages/add';
import SpecificationPackageEditPage from 'my-account/pages/specification-packages/edit';
import SpecificationPackagePublicPage from 'my-account/pages/specification-packages/public';

import MediaLibraryPage from 'my-account/pages/media-library';
import MediaCreatePage from 'my-account/pages/media-library/add';
import MediaEditPage from 'my-account/pages/media-library/edit';
import MediaLibraryViewPage from 'my-account/pages/media-library/view';

import IntroductionsPage from 'my-account/pages/introductions';
import IntroductionsCreatePage from 'my-account/pages/introductions/add';
import IntroductionsEditPage from 'my-account/pages/introductions/edit';

import ResourcesPage from 'my-account/pages/resources';
import ResourcesCreatePage from 'my-account/pages/resources/add';
import ResourcesEditPage from 'my-account/pages/resources/edit';
import ResourcesViewPage from 'my-account/pages/resources/view';
import RequestResourceAccessPage from './pages/auth/request-resource-access';

import ScormsPage from 'my-account/pages/scorms';
import ScormTrackingsViewPage from 'my-account/pages/scorms/view';
import TopicsPage from './pages/resources/topics';
import UniversityPage from './pages/resources/university';

export function MyAccountPortal() {
  usePageTracking();

  return (
    <Switch>
      <AuthRoute path="/auth/login" component={LoginPage} />
      <AuthRoute path="/auth/forgot-password" component={ForgotPasswordPage} />
      <AuthRoute path="/auth/salesforce" component={SalesforceSSOPage} />
      <AuthRoute path="/auth/request-account" component={RequestAccountPage} />
      <AuthRoute path="/auth/resources" component={RequestResourceAccessPage} />
      <Route path="/auth/verify-reset" component={VerifyResetPage} />
      <Route path="/auth/verify-welcome" component={VerifyWelcomePage} />
      <Route path="/share/specification-packages/:token" component={SpecificationPackagePublicPage} />
      <PrivateRoute path="/">
        <Switch>
          <Route path="/profile" component={ProfilePage} />
          <Route path="/" exact component={DashboardPage} />

          {/* Companies */}
          <AbilityGuardedRoute action="create" subject="Agency" path="/agencies/add" component={AgencyCreatePage} />
          <AbilityGuardedRoute action="read" subject="Agency" path="/agencies/:id(\d+)" component={AgencyEditPage} />
          <AbilityGuardedRoute action="list" subject="Agency" path="/agencies" component={AgenciesPage} />

          {/* Companies */}
          <AbilityGuardedRoute action="create" subject="Company" path="/companies/add" component={CompanyCreatePage} />
          <AbilityGuardedRoute action="read" subject="Company" path="/companies/:id(\d+)" component={CompanyEditPage} />
          <AbilityGuardedRoute action="list" subject="Company" path="/companies" component={CompaniesPage} />

          {/* Locations */}
          <AbilityGuardedRoute
            action="read"
            subject="Location"
            path="/locations/:id(\d+)"
            component={LocationEditPage}
          />
          <AbilityGuardedRoute action="list" subject="Location" path="/locations" component={LocationsPage} />

          {/* Users */}
          <AbilityGuardedRoute action="create" subject="User" path="/users/add" component={UserCreatePage} />
          <AbilityGuardedRoute action="read" subject="User" path="/users/:id(\d+)" component={UserEditPage} />
          <AbilityGuardedRoute action="list" subject="User" path="/users" component={UsersPage} />

          {/* Orders */}
          <AbilityGuardedRoute action="read" subject="Quote" path="/orders/pending" component={PendingOrdersPage} />
          <AbilityGuardedRoute action="read" subject="Order" path="/orders/:id(\d+)" component={OrderViewPage} />
          <AbilityGuardedRoute action="list" subject="Order" path="/orders" component={OrdersPage} />

          {/* Quotes */}
          <AbilityGuardedRoute action="read" subject="Quote" path="/quotes/:id(\d+)" component={QuoteViewPage} />
          <AbilityGuardedRoute action="list" subject="Quote" path="/quotes" component={QuotesPage} />

          {/* Specification Packages */}
          <AbilityGuardedRoute
            action="create"
            subject="Project"
            path="/specification-packages/add"
            component={SpecificationPackageCreatePage}
          />
          <AbilityGuardedRoute
            action="read"
            subject="Project"
            path="/specification-packages/:id(\d+)/:version?"
            component={SpecificationPackageEditPage}
          />
          <AbilityGuardedRoute
            action="list"
            subject="Project"
            path="/specification-packages"
            component={SpecificationPackagesPage}
          />

          {/* Media Library */}
          <AbilityGuardedRoute action="create" subject="File" path="/media-library/add" component={MediaCreatePage} />
          <AbilityGuardedRoute
            action="update"
            subject="File"
            path="/media-library/:id(\d+)/edit"
            component={MediaEditPage}
          />
          <Route path="/media-library/:id(\d+)" component={MediaLibraryViewPage} />
          <Route path="/media-library" component={MediaLibraryPage} />

          {/* Resources */}
          <AbilityGuardedRoute
            action="create"
            subject="Resource"
            path="/resources/add"
            component={ResourcesCreatePage}
          />
          <AbilityGuardedRoute action="update" subject="Resource" path="/resources/topics" component={TopicsPage} />
          <AbilityGuardedRoute
            action="update"
            subject="Resource"
            path="/resources/:id(\d+)/edit"
            component={ResourcesEditPage}
          />
          <AbilityGuardedRoute
            action="read"
            subject="Resource"
            path="/resources/:id(\d+)"
            component={ResourcesViewPage}
          />
          <AbilityGuardedRoute action="list" subject="Resource" path="/resources" component={ResourcesPage} />
          <AbilityGuardedRoute action="list" subject="Resource" path="/university" component={UniversityPage} />

          {/* Introductions */}
          <AbilityGuardedRoute
            action="create"
            subject="Introduction"
            path="/introductions/add"
            component={IntroductionsCreatePage}
          />
          <AbilityGuardedRoute
            action="update"
            subject="Introduction"
            path="/introductions/:id(\d+)"
            component={IntroductionsEditPage}
          />
          <AbilityGuardedRoute
            action="list"
            subject="Introduction"
            path="/introductions"
            component={IntroductionsPage}
          />

          {/* Scorms */}
          <AbilityGuardedRoute action="list" subject="Scorm" path="/scorms" component={ScormsPage} />

          <AbilityGuardedRoute
            action="list"
            subject="Scorm"
            path="/scorm/tracking"
            component={ScormTrackingsViewPage}
          />

          {/* 404 page */}
          <Route path="*" component={Page404Error} />
        </Switch>
      </PrivateRoute>
    </Switch>
  );
}
