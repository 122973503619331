import * as React from 'react';
import { z } from 'zod';

import api from 'api';
import { useAbility } from 'auth';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { DataTableListing } from 'my-account/components/DataTable/Listing';
import { MainHero } from 'my-account/components/MainHero';
import { Modal } from 'my-account/components/Modal';
import InputField from 'components/Form/InputField';
import { TaxonomyTermConnect } from '__generated-api__';
import Form from 'components/Form/Form';
import SubmitButton from 'components/Form/SubmitButton';
import CheckboxField from 'components/Form/CheckboxField';
import Select, { components } from 'react-select';
import Icon from 'components/icon';
import { Link } from 'react-router-dom';
import { useToast } from 'my-account/toast';

export default function TopicsPage() {
  useDocumentTitle('University Topics');
  const toast = useToast();

  const ability = useAbility();
  const canCreateResource = ability.can('create', 'Resource');
  const [editedTopic, setEditedTopic] = React.useState<TaxonomyTermConnect>();
  const [isEditorModalOpen, setIsEditorModalOpen] = React.useState(false);
  const [selectedIcon, setSelectedIcon] = React.useState<string | undefined>();

  const onClose = () => {
    setSelectedIcon(undefined);
    setEditedTopic(undefined);
    setIsEditorModalOpen(false);
  };

  const editTopic = (topic: TaxonomyTermConnect) => {
    setEditedTopic(topic);
    setSelectedIcon(topic.icon ?? '');
    setIsEditorModalOpen(true);
  };

  const newTopic = () => {
    setEditedTopic(undefined);
    setSelectedIcon(undefined);
    setIsEditorModalOpen(true);
  };

  return (
    <>
      <MainHero />
      <section className="c-block c-block--hero">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow u-items-end">
            <div className="o-col-12">
              <div className="c-block__header c-block__header--hor u-mt-spacer-base-large">
                <div className="c-block__header-content u-items-center u-block u-flex@sm">
                  <div>
                    <Link
                      to="/resources"
                      className="c-link-cta-basic c-link-cta--small c-block__get-back u-mt-spacer-base-large u-mb-spacer-base-small"
                    >
                      <Icon name="arrow" className="o-svg-icon o-svg-left" />
                      <span>Back to University</span>
                    </Link>
                    <h1 className="c-title--medium u-mb-0">Topics</h1>
                  </div>

                  <div className="u-ml-auto@sm">
                    <Link
                      to="/resources/topics/"
                      className="c-button c-button--primary c-button--small u-my-spacer-base-small u-mr-spacer-base"
                      onClick={newTopic}
                    >
                      <Icon name="add" className="o-svg-icon o-svg-right" />
                      <span>New topic</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__pattern--top3 c-block__overlay--opacity-20">
          <Icon name="pattern" className="o-svg-icon" />
        </div>
      </section>

      {!isEditorModalOpen && (
        <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
          <div className="o-container-fluid">
            <DataTableListing
              label="topics"
              availableSortOptions={{
                updated_at: 'Recently Updated',
                created_at: 'Latest',
              }}
              defaultPerPage={48}
              defaultSort="updated_at"
              defaultDirection="desc"
              queryFn={api.resourceCategories.listResourceCategories}
              queryFnParams={(filters, searchParams) => {
                return {
                  ...filters,
                };
              }}
            >
              {(data) =>
                data.map((topic) => (
                  <Link
                    key={topic.id}
                    to={`/resources/topics/${topic.id}`}
                    onClick={() => editTopic(topic)}
                    className="c-data-card"
                  >
                    <div className="c-data-card__column c-data-card__column--first">
                      <p className="c-data-card__title">{topic.term}</p>
                      <p className="c-data-card__label">Label</p>
                    </div>

                    <div className="c-data-card__column">
                      <p className="c-data-card__title">
                        <Icon
                          name={topic.icon ?? ''}
                          className="o-svg-icon o-svg-large u-ml-auto u-mr-spacer-base-small"
                        />
                      </p>
                      <p className="c-data-card__label">Icon</p>
                    </div>

                    <div className="c-data-card__column">
                      <p>
                        {topic.featured ? (
                          <Icon name="checkmark" className="o-svg-icon o-svg-large u-mr-spacer-base-small" />
                        ) : null}
                      </p>
                      <p className="c-data-card__label">Featured</p>
                    </div>
                    <div className="c-data-card__column">
                      <p>
                        {topic.dashboard ? (
                          <Icon name="checkmark" className="o-svg-icon o-svg-large u-mr-spacer-base-small" />
                        ) : null}
                      </p>
                      <p className="c-data-card__label">Dashboard</p>
                    </div>
                    <div className="c-data-card__column">
                      <button className="c-link-cta-basic c-link-cta--small" type="button">
                        <span>Edit</span>
                        <Icon name="arrow" className="o-svg-icon o-svg-right" />
                      </button>
                    </div>
                  </Link>
                ))
              }
            </DataTableListing>
          </div>
        </section>
      )}
      {isEditorModalOpen && (
        <Modal isOpen={isEditorModalOpen} onRequestClose={onClose}>
          <div className="c-modal" style={{ width: '80%' }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              className="c-modal-close"
              onClick={(event) => {
                event.preventDefault();
                onClose();
              }}
            />
            <Form
              schema={z.object({ term: z.string(), featured: z.boolean(), icon: z.string(), dashboard: z.boolean() })}
              onSubmit={async (values, ctx) => {
                values.icon = selectedIcon ?? '';
                if (canCreateResource) {
                  if (editedTopic) {
                    await api.update
                      .updateResourceCategory({
                        id: editedTopic.id as number,
                        updateResourceCategory: values,
                      })
                      .finally(() => {
                        onClose();
                      });
                  } else {
                    await api.resourceCategory.createResourceCategory({ createCategoryBody: values }).finally(() => {
                      onClose();
                    });
                  }
                }
              }}
              initialValues={{
                term: editedTopic?.term ?? '',
                featured: editedTopic?.featured ?? false,
                icon: editedTopic?.icon ?? '',
                dashboard: editedTopic?.dashboard ?? false,
              }}
              className="u-mb-0"
              formProps={{ mode: 'onSubmit' }}
            >
              <div className="c-modal__main">
                <div className="c-block c-block--spacing-t-small c-block--spacing-b-small">
                  <div className="o-container-fluid">
                    <div className="o-row">
                      <div className="o-col-12">
                        <InputField name="term" label="Topic" elStyle="fill" />
                        <CheckboxField name="featured" label="Featured" checkboxStyle="toggle" />
                        <CheckboxField name="dashboard" label="Show on dashboard" checkboxStyle="toggle" />
                        <Select
                          name="icon"
                          defaultValue={iconOptions.find((option) => option.value === editedTopic?.icon)}
                          options={iconOptions}
                          components={{ Option: IconOption, SingleValue: IconSingleValue }}
                          className="u-mb-spacer-base"
                          onChange={(value) => {
                            setSelectedIcon(value?.value);
                          }}
                        />
                        <div className="c-form-footer">
                          <SubmitButton variant="secondary" isFull>
                            Save
                          </SubmitButton>

                          {editedTopic && (
                            <button
                              onClick={async (event) => {
                                event.preventDefault();
                                const topicName = editedTopic.term;
                                if (
                                  window.confirm(`Are you sure that you really want to delete "${topicName}" topic.`)
                                ) {
                                  //await api.resource([{ userId: editedTopic?.id }]);
                                  await api.delete
                                    .deleteResourceCategory({ id: editedTopic.id as number })
                                    .finally(() => {
                                      toast.notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: `The "${topicName}" topic was deleted successfully.`,
                                      });
                                      onClose();
                                    });
                                }
                              }}
                              type="button"
                              className="u-mt-spacer-base"
                            >
                              <span className="c-data-card-toolbar__label">Delete Topic</span>
                              <Icon name="trash" className="c-data-card-toolbar__icon" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
}

const iconOptions = [
  { value: 'agent', label: 'Agent' },
  { value: 'book', label: 'Book' },
  { value: 'camera', label: 'Camera' },
  { value: 'delivery', label: 'Delivery' },
  { value: 'dimensions', label: 'Dimensions' },
  { value: 'envelope', label: 'Envelope' },
  { value: 'eye', label: 'Eye' },
  { value: 'gallery-fill', label: 'Gallery' },
  { value: 'glasses', label: 'Glasses' },
  { value: 'handyman', label: 'Handyman' },
  { value: 'help', label: 'Help' },
  { value: 'item-install', label: 'Install' },
  { value: 'item-pdf', label: 'PDF' },
  { value: 'location', label: 'Location' },
  { value: 'office', label: 'Office' },
  { value: 'package', label: 'Package' },
  { value: 'plans', label: 'Plans' },
  { value: 'product-mockup', label: 'Product' },
  { value: 'service', label: 'Service' },
  { value: 'university', label: 'University' },
  { value: 'user', label: 'User' },
];

const { Option, SingleValue } = components;
const IconOption = (props: any) => (
  <Option {...props}>
    <Icon name={props.data.value} className="o-svg-icon o-svg-large u-ml-auto u-mr-spacer-base-small" />
    {props.data.label}
  </Option>
);
const IconSingleValue = (props: any) => (
  <SingleValue {...props}>
    <Icon name={props.data.value} className="o-svg-icon o-svg-large u-ml-auto u-mr-spacer-base-small" />
    {props.data.label}
  </SingleValue>
);
