import * as React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';

import { ResourceStatus } from '__generated-api__';
import api from 'api';
import { useAbility } from 'auth';
import { FiltersAccordion } from 'components/Accordion';
import { AutoSubmit } from 'components/Form/AutoSubmit';
import { CheckboxWithCountField } from 'components/Form/CheckboxField';
import Form from 'components/Form/Form';
import Icon from 'components/icon';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { DataTableHero } from 'my-account/components/DataTable/Hero';
import { DataTableClearFilterForm, DataTableListing } from 'my-account/components/DataTable/Listing';
import { MainHero } from 'my-account/components/MainHero';
import { SelectTopic } from 'my-account/components/SelectTopic';
import { setLocationSearchParams } from 'utils/router';

const resourceFilterSchema = z.object({
  status_active: z.boolean(),
  status_inactive: z.boolean(),
  topic: z
    .object({
      id: z.number().positive(),
    })
    .nullable()
    .optional(),
});

const ResourceFilters: React.VFC<{ counts?: { active: number; inactive: number } }> = ({ counts }) => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const initialValueParse = resourceFilterSchema.safeParse({
    status_active: searchParams.getAll('status')?.includes('active') ?? false,
    status_inactive: searchParams.getAll('status')?.includes('inactive') ?? false,
    topic: searchParams.has('topic') ? { id: Number(searchParams.get('topic')!), name: 'Loading...' } : undefined,
  });

  return (
    <>
      <Form
        schema={resourceFilterSchema}
        initialValues={initialValueParse.success ? initialValueParse.data : {}}
        onSubmit={async (values) => {
          const status: Array<'active' | 'inactive'> = [];
          if (values.status_active) {
            status.push('active');
          }

          if (values.status_inactive) {
            status.push('inactive');
          }
          history.replace(
            setLocationSearchParams(location, {
              status,
              topic: values.topic ? String(values.topic.id) : undefined,
              page: undefined,
              'list-page': undefined,
            })
          );
        }}
        isFiltersForm
      >
        <DataTableClearFilterForm />
        <AutoSubmit />

        <SelectTopic
          name="topic"
          label="Topic"
          elStyle="fill"
          small
          queryParams={{ sort: 'name', direction: 'asc' }}
          selectProps={{ isClearable: true }}
        />

        <FiltersAccordion title="Resource Status">
          <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
              <CheckboxWithCountField label="Active" name="status_active" count={counts?.active} />
              <CheckboxWithCountField label="Inactive" name="status_inactive" count={counts?.inactive} />
            </ul>
          </div>
        </FiltersAccordion>
      </Form>
    </>
  );
};

export default function ResourcesPage() {
  useDocumentTitle('University');
  const ability = useAbility();
  const canCreateResource = ability.can('create', 'Resource');

  return (
    <>
      <MainHero />

      <DataTableHero
        title="University"
        // actions={<SpecificationPackagesActions />}
        buttons={
          canCreateResource ? (
            <>
              <Link
                to="/resources/add"
                className="c-button c-button--primary c-button--small u-my-spacer-base-small u-mr-spacer-base"
              >
                <Icon name="add" className="o-svg-icon o-svg-right" />
                <span>New resource</span>
              </Link>
              <Link
                to="/resources/topics"
                className="c-button c-button--primary c-button--small u-my-spacer-base-small"
              >
                <span>Edit Topics</span>
              </Link>
            </>
          ) : undefined
        }
      />

      <section className="c-block c-block--spacing-b-extra-small c-block--spacing-b@md">
        <div className="o-container-fluid">
          <DataTableListing
            label="resources"
            availableSortOptions={{
              created_at: 'Latest',
              updated_at: 'Recently Updated',
            }}
            defaultPerPage={48}
            defaultSort="created_at"
            defaultDirection="desc"
            queryFn={api.resource.listResources}
            queryFnParams={(filters, searchParams) => {
              const status: ResourceStatus[] | undefined = searchParams.has('status')
                ? searchParams
                    .getAll('status')
                    .filter((val): val is ResourceStatus =>
                      [ResourceStatus.Active, ResourceStatus.Inactive].includes(val as unknown as ResourceStatus)
                    )
                : undefined;
              console.log({
                ...filters,
                status: Array.isArray(status) && status.length ? status : undefined,
                topic: String(searchParams.get('topic')) ?? undefined,
                type: String(searchParams.get('type')) ?? undefined,
              });
              return {
                ...filters,
                status: Array.isArray(status) && status.length ? status : undefined,
                topic: String(searchParams.get('topic')) ?? undefined,
                type: String(searchParams.get('type')) ?? undefined,
              };
            }}
            filters={(query) => <ResourceFilters counts={query[0]?.data?.filters?.status} />}
          >
            {(data) => (
              <ul className="o-stack-2 o-stack-3@sm o-stack-4@md c-card__row--media">
                {data.map((resource) => (
                  <li key={resource.id}>
                    <article className="c-card c-card--media c-card--link">
                      <div className="c-card__body">
                        <figure className="c-card__figure">
                          <Link to={`/resources/${resource.id}`}>
                            <img src={resource.image.thumbnail} alt={resource.image.name} />
                          </Link>
                        </figure>
                        <header className="c-card__header">
                          <h4 className="c-card__title u-mb-spacer">
                            <Link key={resource.id} to={`/resources/${resource.id}`} className="c-card__link">
                              {resource.name ?? resource.name}
                            </Link>
                          </h4>
                          {resource.type === 'scorm' && (
                            <span>
                              {resource.scorm_user_progress ? (
                                resource.scorm_user_progress.lesson_status === 'passed' ? (
                                  <span className="c-tag c-tag--primary">
                                    Completed <Icon name="checkmark" className="o-svg-icon u-ml-spacer u-mr-0" />
                                  </span>
                                ) : (
                                  <span className="c-tag c-tag--secondary">
                                    In Progress{' '}
                                    <Icon name="chevron" className="o-svg-icon o-svg-right u-ml-spacer u-mr-0" />
                                  </span>
                                )
                              ) : (
                                <span className="c-tag">
                                  Start course{' '}
                                  <Icon name="chevron" className="o-svg-icon o-svg-right u-ml-spacer u-mr-0" />
                                </span>
                              )}
                            </span>
                          )}
                        </header>
                      </div>
                      {canCreateResource && (
                        <footer className="c-card__footer">
                          <Link to={`/resources/${resource.id}/edit`} className="c-button c-button--small">
                            Edit
                          </Link>
                        </footer>
                      )}
                    </article>
                  </li>
                ))}
              </ul>
            )}
          </DataTableListing>
        </div>
      </section>
    </>
  );
}
