import * as React from 'react';
import api from 'api';
import { useQuery } from 'hooks/query';

import { Link } from 'react-router-dom';
import Icon from 'components/icon';

interface ResourcesFeaturedProps {}

export const ResourcesFeatured: React.VFC<ResourcesFeaturedProps> = () => {
  const [resourcesFeatured] = useQuery(api.resource.listResources, { featured: true });

  return (
    <>
      {resourcesFeatured?.data && resourcesFeatured?.data.data.length > 0 && (
        <div className="u-mt-spacer-base@lg">
          <h5>Featured Content</h5>
          <div className="o-row">
            <div className="o-col-12@lg">
              {resourcesFeatured?.data &&
                resourcesFeatured.data.data.map((item) => (
                  <Link className="c-link-cta" to={'/resources/' + item.id} key={item.id}>
                    <div className="c-admin-card c-admin-card--spacing-sm c-admin-card--info c-admin-card--hor">
                      <div className="c-admin-card__icon">
                        <Icon name={item.topics[0].icon ?? ''} />
                      </div>
                      <div className="c-admin-card__content">
                        <p className="c-admin-card__subtitle">{item.name}</p>
                        <p className="c-admin-card__label">{item.topics.map((item) => item.term).join(', ')}</p>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
