import api from 'api';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { DataTableListing } from 'my-account/components/DataTable/Listing';
import { MainHero } from 'my-account/components/MainHero';
import React from 'react';
import { useQuery } from 'hooks/query';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

export default function ScormTrackingsViewPage() {
  const location = useLocation();
  const searchParams = React.useMemo(() => new URLSearchParams(location.search), [location.search]);

  const user_id = searchParams.get('user_id');
  const scorm_id = searchParams.get('scorm_id');

  const [userResources] = useQuery(api.user.getUser, { userId: Number(user_id) }, { enabled: user_id !== null });

  const [scormResources] = useQuery(api.scorm.getScorm, { id: Number(scorm_id) }, { enabled: scorm_id !== null });

  const allScormsShown = !scorm_id && !user_id;

  //const [scormResources, getScormState] = useQuery(api.scorm.getScorm, { id: Number(id) });
  //const [deleteScorm] = useMutation(api.scorm.deleteScorm);
  //const toast = useToast();
  useDocumentTitle('Scorm Scores');
  //   let canUpdate = false;
  //   if (scormRes?.data) {
  //     canUpdate = ability.can('update', subject('Scorm', scormRes?.data));
  //   }

  return (
    <>
      <MainHero />

      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-t-small@lg c-block--spacing-b-extra-small c-block--spacing-b-small@lg c-block--bg-light2 c-block--hero c-block--columns@lg">
        <div className="o-container-fluid">
          <div className="o-row u-flex-grow">
            <div className="o-col-12@lg">
              <div className="c-hero__content u-justify-around">
                <div className="c-block__header c-block__header--large">
                  <p className="c-headline">Scorm Scores</p>
                  {userResources?.data && (
                    <h1 className="c-title--extra-large">
                      {userResources?.data.first_name + ' ' + userResources?.data.last_name}
                    </h1>
                  )}
                  {scormResources?.data && <h1 className="c-title--extra-large">{scormResources?.data.title}</h1>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="c-block__pattern c-block__pattern--light3 c-block__overlay--opacity-40"></div>
      </section>

      {/* {getScormState.isLoading && (
        <div className="c-listing__none">
          <div className="c-listing__none-spinner"></div>
          <p className="c-listing__none-title">Loading...</p>
        </div>
      )}
      {getScormState.isError && (
        <div className="o-container-fluid u-pb-spacer-section">
          <div className="o-row u-flex-grow u-items-center">
            <div className="o-col-12">
              <div className="c-listing__none">
                <div className="c-listing__none-figure">
                  <Icon name="error" className="o-svg-icon" />
                </div>
                <p className="c-listing__none-title">Error</p>
                <ErrorMessagesContent error={getScormState.error} />
              </div>
            </div>
          </div>
        </div>
      )} */}
      <section className="c-block c-block--spacing-t-extra-small c-block--spacing-t@lg c-block--spacing-b-small c-block--spacing-b@lg c-block--divider-internal">
        <div className="o-container-fluid">
          <DataTableListing
            label="scorm"
            availableSortOptions={{
              created_at: 'Latest',
              name: 'Alphabetical',
            }}
            defaultSort="created_at"
            defaultDirection="asc"
            queryFn={api.scorm.listScormTrackings}
            queryFnParams={(filters, searchParams) => {
              return {
                ...filters,
                //...searchParams,
                scormId: Number(searchParams.get('scorm_id')) > 0 ? Number(searchParams.get('scorm_id')) : undefined,
                userId: Number(searchParams.get('user_id')) ?? undefined,
              };
            }}
          >
            {(data) => (
              <>
                {data.map((tracking) => (
                  <Link
                    key={tracking.id}
                    to={`/users/${tracking.user_id}`}
                    className="c-data-card c-data-card--project"
                  >
                    {!user_id && (
                      <div
                        className={classNames('c-data-card__column', {
                          'c-data-card__column--first': !allScormsShown,
                          'c-data-card__column--large': allScormsShown,
                        })}
                      >
                        <p className="c-data-card__title">
                          {tracking.user?.first_name + ' ' + tracking.user?.last_name}
                        </p>
                        <p className="c-data-card__label">User</p>
                      </div>
                    )}

                    {!scorm_id && (
                      <div
                        className={classNames('c-data-card__column', {
                          'c-data-card__column--first': !allScormsShown,
                          'c-data-card__column--large': allScormsShown,
                        })}
                      >
                        <p className="c-data-card__title">{tracking.scorm?.title}</p>
                        <p className="c-data-card__label">Scorm Resource</p>
                      </div>
                    )}

                    <div className="c-data-card__column">
                      <p className="c-data-card__title">
                        {tracking.lesson_status === 'passed'
                          ? 'Passed ' + tracking.score_raw + '/' + tracking.score_max
                          : 'In Progress'}
                      </p>
                      <p className="c-data-card__label">State</p>
                    </div>

                    <div className="c-data-card__column">
                      <p className="c-data-card__small">
                        {tracking.created_at && format(parseISO(tracking.created_at), 'MMM d, y, h:m aaa')}
                      </p>
                      <p className="c-data-card__label">Started on</p>
                    </div>
                  </Link>
                ))}
              </>
            )}
          </DataTableListing>
        </div>
      </section>
    </>
  );
}
