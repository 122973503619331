import * as React from 'react';
import { useController } from 'react-hook-form';
import { MutationStatus } from 'react-query';

import { FileModel, FileType } from '__generated-api__';
import api from 'api';
import Icon from 'components/icon';
import { useMutation } from 'hooks/query';

export const MultiFileField: React.VFC<{
  name: string;
  type: FileType;
  onUploadStatusChange?: (status: MutationStatus) => void;
  onUploadStart: () => void;
  onFileUpload?: (File: FileModel[]) => void;
  fieldLabel?: string;
  acceptedFileTypes?: string;
  note?: string;
}> = ({
  name,
  type = FileType.File,
  onUploadStatusChange,
  onUploadStart,
  onFileUpload,
  fieldLabel,
  acceptedFileTypes,
  note,
}) => {
  const [files, setFiles] = React.useState<FileModel[]>([]);
  const [progress, setProgress] = React.useState<File[]>([]);
  const { field, fieldState } = useController({ name });
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [uploadFile, uploadFileMutation] = useMutation(api.file.upload);

  React.useEffect(() => {
    setFiles(field.value || []);
  }, [field.value]);

  React.useEffect(() => {
    if (!onUploadStatusChange) {
      return;
    }

    onUploadStatusChange(uploadFileMutation.status);
  }, [uploadFileMutation.status, onUploadStatusChange]);

  return (
    <div className="c-form-element c-form-element--style-fill c-form-element--file">
      <div className="c-form-element__field">
        <input
          type="file"
          style={{ position: 'fixed', top: '-100em' }}
          ref={fileInputRef}
          multiple={true}
          accept={acceptedFileTypes ?? '.zip,.jpeg,.png,.gif,.jpg,.docx,.doc,.pdf,.xls,.xlsx,.csv,.txt,.ppt,.pptx'}
          onChange={async (event) => {
            if (!event.currentTarget.files || !event.currentTarget.files.length) {
              field.onChange(files);
              return;
            }
            if (onUploadStart) onUploadStart();
            const uploadedFiles = Array.from(event.currentTarget.files);
            await setProgress((prev) => [...prev, ...uploadedFiles]);
            const save = files;
            await Promise.all(
              uploadedFiles.map(async (file) => {
                await uploadFile([{ image: file, type }]).then((uploadRes) => {
                  setProgress((prev) => prev.filter((f) => f !== file));
                  save.push(uploadRes.data);
                });
              })
            ).then(() => {
              setFiles(save);
              field.onChange(save);
              if (onFileUpload) onFileUpload(save);
            });
          }}
        />
      </div>

      {progress.length > 0 && (
        <>
          <div className="c-profile-preview__footer">
            {progress.map((file, index) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={index}
                href="#"
                className="c-button c-button--light-grey c-button--option"
                onClick={(event) => {
                  event.preventDefault();
                  setProgress((prev) => prev.filter((f) => f !== file));
                  field.onChange(files);
                }}
              >
                <Icon name="close-thin" className="o-svg-icon" />
                <span>Uploading: {file.name}</span>
              </a>
            ))}
          </div>
          {typeof fieldState.error !== 'undefined' && (
            <ul className="c-form-element--error__list" style={{ display: 'block' }}>
              {typeof fieldState.error.message !== 'undefined' && <li key="message">{fieldState.error.message}</li>}
              {typeof fieldState.error.types !== 'undefined' &&
                Object.entries(fieldState.error.types).map(([key, msg]) => <li key={key}>{msg}</li>)}
            </ul>
          )}
        </>
      )}
      {files.length > 0 && (
        <>
          <div className="c-profile-preview__footer">
            {files.map((file: FileModel, index: number) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={index}
                href="#"
                className="c-button c-button--light-grey c-button--option"
                onClick={async (event) => {
                  event.preventDefault();
                  const data = files.filter((f) => f !== file);
                  await setFiles(data);
                  field.onChange(data);
                }}
              >
                <Icon name="close-thin" className="o-svg-icon" />
                <span>{file.name}</span>
              </a>
            ))}
          </div>

          {fieldState.error && (
            <ul className="c-form-element--error__list" style={{ display: 'block' }}>
              {typeof fieldState.error.message !== 'undefined' && <li key="message">{fieldState.error.message}</li>}
              {typeof fieldState.error.types !== 'undefined' &&
                Object.entries(fieldState.error.types).map(([key, msg]) => <li key={key}>{msg}</li>)}
            </ul>
          )}
        </>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        className="c-profile-preview__add"
        onClick={(event) => {
          event.preventDefault();
          fileInputRef.current?.click();
        }}
      >
        <div>
          <Icon name="camera" />
        </div>

        <div>
          <button className="c-link-cta-basic c-link-cta--small" type="button">
            {fieldLabel ?? (type === FileType.Image ? <span>Add Image</span> : <span>Add documents</span>)}
          </button>
        </div>
      </a>

      {fieldState.error && (
        <ul className="c-form-element--error__list" style={{ display: 'block' }}>
          {typeof fieldState.error.message !== 'undefined' && <li key="message">{fieldState.error.message}</li>}
          {typeof fieldState.error.types !== 'undefined' &&
            Object.entries(fieldState.error.types).map(([key, msg]) => <li key={key}>{msg}</li>)}
        </ul>
      )}

      <p className="c-note">
        {note ? (
          <span>{note}</span>
        ) : (
          'ZIP, DOC, DOCX, PPT, PPTX, PDF, XLS, XLSX, CSV, JPG, JPEG, PNG, or GIF formats are accepted.'
        )}
      </p>
    </div>
  );
};
