import * as React from 'react';
import { observer } from 'mobx-react';
import { RefinementList } from 'react-instantsearch-dom';

import DocumentSearchStore from 'search/stores/document-search-store';

interface DocumentTypeFilterProps {
  store: DocumentSearchStore;
}

const DocumentTypeFilter = observer(
  class DocumentTypeFilter extends React.Component<DocumentTypeFilterProps> {
    private containerRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
      const { store } = this.props;
      const { documentTypeFilterOpened: opened } = store;
      if (!opened && this.containerRef.current) {
        this.containerRef.current.style.display = 'none';
      }
    }

    transformItems = (items: any) => {
      return items
        .map((item: any) => {
          var customLabel = item.label;
          if (customLabel === 'Main Image') {
            customLabel = 'Product Images';
          } else if (customLabel === 'Lifestyle Image') {
            customLabel = 'Product Lifestyles';
          } else if (customLabel === 'Case Study') {
            customLabel = 'Case Studies';
          } else if (customLabel === 'Specification Submittal') {
            customLabel = 'Specification Submittals';
          } else if (customLabel === '3D CAD File (RFA)') {
            customLabel = '3D CAD Files (RFA)';
          } else if (customLabel === 'Product Sheet') {
            customLabel = 'Product Sheets';
          } else if (customLabel === 'Brochure') {
            customLabel = 'Brochures';
          } else if (customLabel === 'Parts Diagram') {
            customLabel = 'Parts Diagrams';
          } else if (customLabel === 'A and D') {
            customLabel = 'A & Ds';
          } else if (customLabel === 'Price Book') {
            customLabel = 'Price Books';
          }

          return {
            ...item,
            label: customLabel,
          };
        })
        .filter((item: any) => item.label !== 'Non-featured Image');
    };

    render() {
      const { store } = this.props;
      const { documentTypeFilterOpened: opened } = store;
      const classes = ['c-filters__group', opened ? 'is-open' : ''];
      return (
        <>
          <div className={classes.join(' ')}>
            <div
              className="c-filters__header c-accordion-toggle"
              onClick={() => {
                store.setDocumentTypeFilterOpened(!opened);
                if (this.containerRef.current) {
                  jQuery(this.containerRef.current).stop(true).slideToggle();
                }
              }}
            >
              <p className="c-filters__title">Document Type</p>
            </div>
            <div className="c-filters__container c-accordion-container" ref={this.containerRef}>
              <RefinementList attribute="types" limit={100} transformItems={this.transformItems} />
            </div>
          </div>
        </>
      );
    }
  }
);

export default DocumentTypeFilter;
